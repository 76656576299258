var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"elevation-0"},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('h2',[_vm._v("ແຜນຕາຕະລາງຈັດສົ່ງນ້ຳ")])])],1),_c('v-card',{staticClass:"elevation-0 outlined"},[_c('v-card-text',{staticClass:"pb-1"},[_c('v-row',[_c('v-col',[_c('v-menu',{ref:"filter_menu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"multiple":"","chips":"","small-chips":"","label":"ເລືອກວັນທີ","prepend-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.filterDates),callback:function ($$v) {_vm.filterDates=$$v},expression:"filterDates"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.filter_menu),callback:function ($$v) {_vm.filter_menu=$$v},expression:"filter_menu"}},[_c('v-date-picker',{attrs:{"multiple":"","scrollable":""},model:{value:(_vm.filterDates),callback:function ($$v) {_vm.filterDates=$$v},expression:"filterDates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.filter_menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.SelectedDates}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.calendars,"disable-pagination":true,"hide-default-footer":"","loading":_vm.TableLoading,"disabled":_vm.TableLoading,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"label":"","color":"success"}},[_vm._v(" "+_vm._s(item.planName)+" ")])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":"info"}},[_vm._v(" "+_vm._s(item.total)+" ")])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(_vm._f("formatDate")(item.created_at))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.ViewRoutePlan(item.id)}}},[_vm._v(" mdi-eye ")])]}}])}),_c('br'),[(_vm.pagination.last_page > 1)?_c('Pagination',{attrs:{"pagination":_vm.pagination,"offset":_vm.offset},on:{"paginate":function($event){return _vm.fetchData()}}}):_vm._e()]],2)],1),_c('ModalDelete',[[_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.closeDelete()}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":"","loading":_vm.loading,"disabled":_vm.loading},on:{"click":function($event){return _vm.deleteItemConfirm()}}},[_vm._v("OK")]),_c('v-spacer')],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }