<template>
  <div>
   <v-card class="elevation-0">
     <v-card-text>

       <v-row>
         <v-col>
           <h2>ແຜນຕາຕະລາງຈັດສົ່ງນ້ຳ</h2>
         </v-col>
       </v-row>

         <v-card class="elevation-0 outlined">
           <v-card-text class="pb-1">
             <v-row>
             <v-col
             >
               <v-menu
                   ref="filter_menu"
                   v-model="filter_menu"
                   :close-on-content-click="false"
                   :nudge-right="40"
                   transition="scale-transition"
                   offset-y
                   min-width="auto"
                   persistent
               >
                 <template v-slot:activator="{ on, attrs }">
                   <v-combobox
                       v-model="filterDates"
                       multiple
                       chips
                       small-chips
                       label="ເລືອກວັນທີ"
                       prepend-icon="mdi-calendar"
                       readonly
                       v-bind="attrs"
                       v-on="on"
                       outlined
                       dense
                   ></v-combobox>
                 </template>
                 <v-date-picker
                     v-model="filterDates"
                     multiple
                     scrollable
                 >
                   <v-spacer></v-spacer>
                   <v-btn
                       text
                       color="primary"
                       @click="filter_menu = false"
                   >
                     Cancel
                   </v-btn>
                   <v-btn
                       text
                       color="primary"
                       @click="SelectedDates"
                   >
                     OK
                   </v-btn>
                 </v-date-picker>
               </v-menu>
             </v-col>
<!--             <v-col class="d-flex">-->
<!--               <v-autocomplete-->
<!--                   v-model="filterDay"-->
<!--                   :items="day"-->
<!--                   label="ວັນ"-->
<!--                   item-text="name"-->
<!--                   item-value="id"-->
<!--                   dense-->
<!--                   outlined-->
<!--                   clearable-->
<!--               ></v-autocomplete>-->
<!--             </v-col>-->

         <!--      <v-col>-->
         <!--        <v-text-field-->
         <!--            outlined-->
         <!--            dense-->
         <!--            clearable-->
         <!--            prepend-inner-icon="mdi-account-search-outline"-->
         <!--            label="ເລກບິນ,ຊື່ລູກຄ້າ,ເບີໂທ"-->
         <!--            type="text"-->
         <!--            v-model="search"-->
         <!--            @keyup.enter="Search()"-->
         <!--        >-->
         <!--        </v-text-field>-->
         <!--      </v-col>-->
       </v-row>
           </v-card-text>
         </v-card>


       <v-data-table
           :headers="headers"
           :items="calendars"
           :disable-pagination="true"
           hide-default-footer
           :loading="TableLoading"
           :disabled="TableLoading"
           loading-text="Loading... Please wait"
           class="elevation-0"
       >
         <template v-slot:item.name="{ item }">
           <v-chip label color="success">
             {{ item.planName}}
           </v-chip>
         </template>
         <template v-slot:item.total="{ item }">
           <v-chip color="info">
             {{ item.total}}
           </v-chip>
         </template>
         <template v-slot:item.created_at="{ item }">
           <div>
             {{ item.created_at | formatDate }}
           </div>
         </template>
         <!--Action -->
         <template v-slot:item.actions="{ item }">
           <v-icon small class="mr-2" @click="ViewRoutePlan(item.id)">
             mdi-eye
     </v-icon>
 <!--   <v-icon small class="mr-2" @click="OpenModalEdit(item)">-->
<!--           mdi-pencil-->
<!--         </v-icon>-->
<!--           <v-icon small @click="exportCalendarPdf(item)"> mdi-download</v-icon>-->
         </template>
       </v-data-table>
       <br />
       <template>
         <Pagination
             v-if="pagination.last_page > 1"
             :pagination="pagination"
             :offset="offset"
             @paginate="fetchData()"
         ></Pagination>
       </template>
     </v-card-text>
   </v-card>

    <!--Delete Modal-->
    <ModalDelete>
      <template>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete()"
            >Cancel</v-btn
          >
          <v-btn
            color="blue darken-1"
            text
            :loading="loading"
            :disabled="loading"
            @click="deleteItemConfirm()"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </template>
    </ModalDelete>
  </div>
</template>

<script>
// import manage from "@/mixins/routeplan/calendar";
import Pagination from "../../../plugins/pagination/pagination";
import queryOption from "../../../Helpers/queryOption";
export default {
  // mixins: [manage],
  components: {
    Pagination,
  },
  data() {
    return {
      //Pagination
      offset: 15,
      pagination: {},
      per_page: 300,
      selectedVehicle:'',

      loading: false,
      TableLoading: false,
      server_errors: {
        truck_id: '',
        phone: ''
      },
      calendar: {},
      trucks: [],
      selectedTruck: '',
      plans: [],
      selectedPlan: '',
      filterPlan: '',
      day: [],
      filterDay: '',
      selectedDay: '',
      dates: [new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)],
      menu: false,

      filterDates: [new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)],
      filter_menu: false,

      edit_menu: false,
      edit_calendar: {},
      calendars: [],

      headers: [
        {
          text: "ວັນທີຈັດສົ່ງ",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "ວັນ",
          align: "start",
          sortable: false,
          value: "day",
        },
        {
          text: "ຊື່ແຜນ",
          align: "start",
          sortable: false,
          value: "name",
        },
        {text: "ບ່ອນຈັດສົ່ງ", value: "total",align:"center"},
        // {text: "ລົດຈັດສົ່ງ", value: "license_plate"},
        {text: "ວັນທີສ້າງແຜນ", value: "created_at"},
        {text: "Actions", value: "actions", sortable: false},
      ],
      idRules: [
        v => !!v || 'Truck ID is required',
        v => (v && v.length >= 2) || 'Truck ID must be more than 5 characters',
      ],
      phoneRules: [
        (v) => !!v || "Phone is required",
        v => (v && v.length >= 4 && v.length <= 11) || 'Phone number must be  4 - 11 numbers',
      ],

      toast: {
        value: true,
        color: 'success',
        msg: 'Success'
      },
      toast_error: {
        value: true,
        color: 'error',
        msg: 'Something when wrong!'
      }
    }
  },
  metaInfo() {
    return {
      title: `ແຜນເວລາຈັດສົ່ງ`,
    }
  },
  methods: {
    OpenModalAdd() {
      this.$store.commit("modalAdd_State", true);
    },


    fetchRoutePlan() {
      this.TableLoading = true;
      this.$axios.get('/driver/plan-calendar',{
        params: queryOption([
          { page: this.pagination.current_page },
          { per_page: this.per_page },
          { dates: this.dates,}
        ]),
      }).then(res => {
          this.plans = res.data.data.data;
          this.TableLoading = false;
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },

    CloseModalAdd() {
      this.truck = {},
          this.reset();
      this.$store.commit("modalAdd_State", false);
    },
    OpenModalEdit(item) {
      this.edit_calendar = item;
      this.$store.commit("modalEdit_State", true);
    },

    CloseModalEdit() {
      this.edit_calendar = {};
      this.reset();
      this.$store.commit("modalEdit_State", false);
    },

    fetchData() {
      this.TableLoading = true;
      this.$axios.get('/branch/plan-calendar',{
        params: queryOption([
          { page: this.pagination.current_page },
          { per_page: this.per_page },
          { dates: this.dates,}
        ]),
      }).then(res => {
        setTimeout(() => {
          this.calendars = res.data.data.data;
          this.pagination = res.data.data;
          this.TableLoading = false;
        }, 100);
      }).catch(() => {
        this.TableLoading = false;
        this.$store.commit("Toast_State", this.toast_error);
      });
    },
    ViewRoutePlan(id) {
      this.$router.push({name: 'BranchCalendarDetail', params: {id: id}});
    },

    closeDelete() {
      this.$store.commit("modalDelete_State", false);
    },
    deleteItem(id) {
      this.truckID = id;
      this.$store.commit("modalDelete_State", true);
    },
    deleteItemConfirm() {
      this.loading = true;
      this.$axios.delete('branch/plan-calendar/' + this.truckID).then(res => {
        if (res.data.success == true) {
          setTimeout(() => {
            this.fetchData();
            this.$store.commit("modalDelete_State", false);
            this.loading = false;
            this.$store.commit("Toast_State", this.toast);
          }, 1000);
        }
      }).catch(() => {
        this.$store.commit("modalDelete_State", false);
        this.loading = true;
        this.$store.commit("Toast_State", this.toast_error);
      })
    },
    reset() {
      this.$refs.form.reset();
    },
    SelectedDates() {
      this.$refs.filter_menu.save();
      this.pagination = [],
          this.fetchData();
    },
    exportCalendarPdf(item) {
      this.$store.commit("progressLoading_State", true);
      this.$axios
          .get(
              "export/calendar/" + item.id, {responseType: "blob"}
          )
          .then((res) => {
            setTimeout(() => {
              const fileUrl = window.URL.createObjectURL(new Blob([res.data]));
              const fileLink = document.createElement("a");
              fileLink.href = fileUrl;
              fileLink.setAttribute(
                  "download",
                  `${item.planName}_${item.date}_${item.license_plate}` +
                  ".xlsx"
              );
              document.body.appendChild(fileLink);
              fileLink.click();
              document.body.removeChild(fileLink);
              this.$store.commit("progressLoading_State", false);
            }, 100);
          })
          .catch(() => {
            this.$store.commit("progressLoading_State", false);
            this.$store.commit("Toast_State", this.toast_error);
          });
    },
    getPlan(value) {
      if (value.description !== null) {
        return value.name + ' ( ' + value.description +' )';
      } else {
        return value.name
      }
    },
  },
  watch: {
    filterDay: function (){
      this.pagination = [],
      this.fetchData();
    },
    selectedVehicle: function (){
      this.pagination = [],
          this.fetchData();
    },
    filterPlan: function (){
      this.pagination = [],
          this.fetchData();
    },
  },
  created() {
    this.fetchData();
  },
};
</script>

<style>
</style>